import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import { withStyles } from '@material-ui/core/styles';

import withRoot from '../../withRoot';

import Layout from '../../components/Layout';
import TextField from '@material-ui/core/TextField/TextField';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DoublePassword from '../../components/DoublePassword';
import FormHelperText from '@material-ui/core/es/FormHelperText/FormHelperText';


const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  input: {
    margin: theme.spacing.unit,
  },
});


class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.axios = this.props.axios;
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  state = {
    userFields: {
      name: '',
      password: '',
      action: 'create',
    },
    error: '',
    formError: '',
    users: [],
    dialogOpen: false,

  };

  getUsers = () => {
    this.props.axios.get('get_users.php')
      .then(res => {
        const data = res.data;
        console.log('users: ', data);
        this.setState({ users: data });
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 400') {
          this.setState({ error: 'Viga. Proovisid ' });
        } else if (err.message === 'Request failed with status code 403') {
          this.setState({ error: 'Viga. Kasutaja pole administraator.' });
        }
        setTimeout(() => this.setState({ error: '' }), 6000);
      });
  };

  componentDidMount() {
    this.getUsers();
  }

  handleChange = event => {

    const key = event.target.id;
    const value = event.target.value;

    const userFields = this.state.userFields;
    userFields[key] = value;

    this.setState((state, props) =>
      Object.assign({}, state, { userFields }),
    );
  };
  handleNew = (event) => {
    event.preventDefault();
    const usernames = this.state.users.map(u => u.name);
    // user already exists
    if (usernames.indexOf(this.state.userFields.name) !== -1) {
      this.handleClickOpen();
    } else {
      // all good
      this.addNewUser();
    }
  };


  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleClose = () => {
    this.setState({ dialogOpen: false });
  };
  addNewUser = () => {
    if (!this.state.isCorrect) {
      this.setState({ error: 'Viga. Paroolid ei ole korrektsed.' });
      setTimeout(() => this.setState({ error: '' }), 6000);
      return;

    }
    this.axios.post('manage_users.php', this.state.userFields)
      .then(res => {
        const data = res.data;
        console.log('result: ', data);
        this.handleClose();
        navigate('/accounts');
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 400') {
          this.setState({ error: 'Viga. Proovisid lisada kasutajat mõnda lahtrit täitmata.' });
        } else if (err.message === 'Request failed with status code 403') {
          this.setState({ error: 'Viga. Kasutaja pole administraator.' });
        }
        setTimeout(() => this.setState({ error: '' }), 6000);
      });
  };

  handlePassword = (isCorrect, password, formError) => {

    const userFieldsOld = this.state.userFields;
    const userFields = Object.assign({}, userFieldsOld, { password });

    this.setState({ userFields, isCorrect, formError });

  };

  render() {
    const { classes } = this.props;

    return (
      <Layout title="Uus kasutaja" error={this.state.error}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <form className={classes.form} autoComplete="off" onSubmit={this.handleNew}>
              <FormHelperText className={classes.input}>
                {this.state.formError}
              </FormHelperText>
              <TextField
                type="username"
                id="name"
                label="Kasutajanimi"
                className={classes.input}
                value={this.state.userFields.name}
                onChange={this.handleChange}
                margin="normal"
                fullWidth
                required
              />
              <DoublePassword checkCallback={this.handlePassword}/>
              <Button
                type="submit"
                className={classes.input}
                variant="contained"
                color="primary"
              >
                Salvesta
              </Button>
            </form>
          </Paper>
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.handleClose}
          >
            <DialogTitle>
              Kasutaja eksisteerib
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sellise nimega kasutaja juba eksisteerib. Ei saa uut sama nimelist kasutajat teha.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Tagasi
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Layout>
    );
  }
}

export default withRoot(withStyles(styles)(NewUser));
